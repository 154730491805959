import * as React from "react"

import { Layout, MenuList } from '@components';

const IndexPage = () => {
  return (
    <Layout>
      <MenuList />
    </Layout>
  )
}

export default IndexPage
